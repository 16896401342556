@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@200&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Poppins',;
  overflow: scroll;
}